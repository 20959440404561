import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBarRef, MatSnackBar } from '@angular/material/snack-bar';
import { Router, ActivatedRoute } from '@angular/router';
import { takeUntil, switchMap, map, catchError, tap, take } from 'rxjs/operators';
import { Subject, Observable, of, combineLatest, from } from 'rxjs';
import { EcaSnackbarAlertComponent, EcaSnackbarAlertConfig, AppContextService, EcaSetupConfigurationService, SetupConfigAttribute, EcaGenericMessageDialogComponent, EcaGenericMessageDialogConfig } from '@drc-eca/eca-components-lib';
import { UserSecurityService } from '../../user/services/user-security.service';
import { PortalError } from '../../shared/portal-error.model';
import { SimpleDialogComponent } from '../simple-dialog/simple-dialog.component';
import jwtDecode from 'jwt-decode';

@Component({
  selector: 'app-activate-account',
  templateUrl: './activate-account.component.html',
  styleUrls: ['./activate-account.component.scss']
})
export class ActivateAccountComponent implements OnInit, OnDestroy {

  showResendLink = false;
  productCode: string;
  productCode$: Observable<string>;
  temporaryToken: string;
  destroyed = new Subject<boolean>();
  attributes$: Observable<{}>;
  useOkta$: Observable<boolean>;
  userId: string;
  isLoading: boolean;
  form = {
    status: 200,
    show: true
  };

  snackBarRef: MatSnackBarRef<EcaSnackbarAlertComponent>;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private userSecurityService: UserSecurityService,
    private appContextService: AppContextService,
    private setupConfigService: EcaSetupConfigurationService,
    private snackBar: MatSnackBar,
    public dialog: MatDialog
  ) {
  }

  ngOnInit() {
    this.productCode$ = this.activatedRoute.queryParams.pipe(
      takeUntil(this.destroyed),
      map((params) => {
        this.productCode = params['productCode'];
        if (this.productCode) {
          this.appContextService.setSelectedProductCode(this.productCode);
        }
        this.temporaryToken = params['token'].replace(/(\r\n|\n|\r)/gm, '');
        this.userId = (jwtDecode(this.temporaryToken) as any).sub;
        return params['productCode'];
      }));

    this.productCode$.subscribe();

    this.attributes$ = this.productCode$.pipe(
      switchMap(productCode => {
        if (productCode) {
          return this.setupConfigService.getAttributesForClient(productCode);
        } else {
          return of([{
            attribute: {
              key: 'login-method'
            },
            value: 'eca'
          }] as SetupConfigAttribute[]);
        }
      }),

      // Map array of attribute objects to a HashMap of key=value
      map(attrs => attrs.reduce((acc, attr) => (acc[attr.attribute.key] = attr.value, acc), {})),
      catchError((error) => {
        if (error.status === 404) {
          this.router.navigate(['not-found']);
          return of({});
        }
        return of({});
      })
    );

    this.useOkta$ = this.attributes$.pipe(
      map(attributes => attributes['login-method'] === 'okta')
    );
  }

  openGoodSnackBar(inMessage: string) {
    this.snackBarRef = this.snackBar.openFromComponent(EcaSnackbarAlertComponent, {
      duration: 2000,
      panelClass: 'mat-snack-positive',
      verticalPosition: 'top',
      data: {
        icon: 'check_circle',
        message: inMessage
      }
    });
  }

  openBadSnackBar(err) {
    const config = new EcaSnackbarAlertConfig(err, 'negative');
    this.snackBarRef = this.snackBar.openFromComponent(EcaSnackbarAlertComponent, config);
  }

  openDialog(config: EcaGenericMessageDialogConfig) {
    return this.dialog.open(EcaGenericMessageDialogComponent, {
      data: config,
      width: '600px',
      position: { top: '70px' },
      panelClass: 'activate-account-dialog'
    });
  }

  saveProfile(newPassword) {
    let pswdObject = {
      password: newPassword,
      token: this.temporaryToken
    };

    combineLatest([this.useOkta$, this.userSecurityService.activateAccount(this.userId, pswdObject), this.productCode$]).pipe(
      switchMap(([useOkta, user, prdCode]) => {
        if (useOkta) {
          this.appContextService.setToken(null);
          if (!prdCode) {
            return from(this.router.navigate(['/login']));
          } else {
            return from(this.router.navigate([`/login/${prdCode}`]));
          }
        } else {
          this.appContextService.setToken(user.token);
          return this.userSecurityService.getUserForToken(user.token).pipe(
            tap(() => {
              // Even though we don't do anything with the getUserForToken response, it has side effects we need to login the user
              this.isLoading = false;
              this.openGoodSnackBar('Your account has been activated. Please login to continue.');
              this.router.navigate(['/login']);
            }));
        }
      })
    ).subscribe(() => {
      this.isLoading = false;

    }, (err: PortalError) => {
      let message;
      this.isLoading = false;

      if (err.status === 400) {
        message = `
                <br/>There was a problem activating your account. Please try again. <br/><br/>It is possible the password you have provided does not meet the criteria for a new password.
                Remember, passwords may not contain your first or last name or your username. In addition, try adding at least one of these characters to the middle of the password:
                !#$%&()*+,-./:;<=>?@[]^_\`{|} ~<br/><br/>`;

        this.dialog.open(SimpleDialogComponent, {
          width: '600px',
          position: { top: '70px' },
          data: {
            title: 'Error',
            message: message
          }
        });
      } else {
        switch (err.status) {
          case 403:
            message = 'Your account activation invitation has expired. For a new account activation link, click on Send a New Link.';
            break;
          case 409:
            message = 'This account has already been activated. If you need to reset your password, please &nbsp; <a href="#/forgot-password">click here</a> &nbsp; to reset your password.';
            break;
          default:
            const friendlyMessage = err.friendlyErrorMessage || 'There was a problem activating your account.';
            message = `${friendlyMessage} Please request a new activation email.`;
        }

        this.showResendLink = true;
        this.form.status = err.status;
        if (err.status === 403) {
          const data: EcaGenericMessageDialogConfig = {
            message: message,
            title: 'Expired Account Activation',
            icon: 'warning-triangle',
            buttons: {
              negative: {
                label: 'Cancel'
              },
              positive: {
                label: 'Send a New Link'
              }
            }
          };

          this.openDialog(data).beforeClosed()
            .pipe(take(1))
            .subscribe(val => {
              if (val) {
                this.resendEmail();
              }
            });
        } else {
          this.openBadSnackBar(message);
        }
      }
    });
  }

  resendEmail() {
    this.loading(true);
    this.userSecurityService.sendActivateAccountEmail(this.productCode, this.temporaryToken).subscribe(() => {
      this.form.show = false;
      this.loading(false);
      this.openGoodSnackBar('A new email has been sent to you.');
    },
      (err) => {
        this.loading(false);
        this.openBadSnackBar('There was a problem sending the email.');
      });
  }

  loading(event) {
    this.isLoading = event;
  }

  ngOnDestroy() {
    if (this.snackBarRef) {
      this.snackBarRef.dismiss();
    }
    this.destroyed.next(true);
    this.destroyed.complete();
  }

}
