// Angular
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import {
  EcaFooterModule,
  EcaHeaderModule,
  EcaSnackbarAlertComponent,
  EcaSnackbarAlertModule,
  EcaComponentsLibModule,
  EcaAccessDeniedModule,
  BrowserDetectorService,
  SkipToContentButtonModule,
  EcaAppShellModule,
  EcaIconModule
} from '@drc-eca/eca-components-lib';
//End of DRC libs

import { DeviceDetectorService } from 'ngx-device-detector';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { environment } from '../environments/environment';
import { CoreModule } from './core/core.module';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    EcaComponentsLibModule.forRoot(environment.apiGatewayConfig),
    FlexLayoutModule,
    CommonModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    EcaHeaderModule,
    EcaFooterModule,
    EcaIconModule,
    HttpClientModule,
    AppRoutingModule,
    CoreModule,
    EcaSnackbarAlertModule,
    EcaAccessDeniedModule,
    SkipToContentButtonModule,
    EcaAppShellModule
  ],
  providers: [
    { provide: BrowserDetectorService, useClass: DeviceDetectorService }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
